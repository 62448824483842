import Cookies from "js-cookie";

const ID_TOKEN_KEY = "token";
const USERS = "users";
const PERMISSIONS = "permissions";

export const getToken = () => {
  return Cookies.get(ID_TOKEN_KEY);
};
export const getUsers = () => {
  return Cookies.get(USERS);
};

export const getPermissions = () => {
  return Cookies.get(PERMISSIONS);
};

export const saveToken = (token) => {
  Cookies.set(ID_TOKEN_KEY, token);
};

export const saveUsers = (users) => {
  Cookies.set(USERS, JSON.stringify(users));
};

export const savePermissions = (permissions) => {
  var CryptoJS = require("crypto-js");

  var permission = CryptoJS.AES.encrypt(
    JSON.stringify(permissions),
    process.env.VUE_APP_KEY
  ).toString();

  Cookies.set(PERMISSIONS, JSON.stringify(permission));
};

export const destroyToken = () => {
  Cookies.remove(ID_TOKEN_KEY);
  Cookies.remove(USERS);
  Cookies.remove(PERMISSIONS);
};

export default {
  getToken,
  getUsers,
  getPermissions,
  saveToken,
  saveUsers,
  savePermissions,
  destroyToken,
};
