import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from "@/core/services/ApiService";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import "@/core/keenthemes";
import "bootstrap";
import ability from "@/core/config/ability";
import { abilitiesPlugin } from "@casl/vue";
import CKEditor from '@ckeditor/ckeditor5-vue';
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import VueApexCharts from "vue3-apexcharts";

const app = createApp(App);

ApiService.init(app);
initInlineSvg(app);
app.use(store);
app.use(router);
app.use(CKEditor );
app.use(VueApexCharts);
app.config.devtools = false;
app.config.debug = false;
app.config.silent = true;

app.component("Loading", Loading);
app.use(abilitiesPlugin, ability);

app.mount("#app");
