import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";
import { VERIFY_AUTH } from "../store/modules/AuthModule";
const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/views/layout/Layout"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/pages/Dashboard.vue"),
      },
      {
        path: "/settings/users",
        name: "settings.users",
        component: () => import("@/views/pages/setting/users/Users.vue"),
        children: [
          {
            path: "/settings/users",
            name: "settings.users.index",
            component: () => import("@/views/pages/setting/users/Index.vue"),
          },
          {
            path: "/settings/users/create",
            name: "settings.users.create",
            component: () => import("@/views/pages/setting/users/Create.vue"),
          },
          {
            path: "/settings/users/:id/edit",
            name: "settings.users.edit",
            component: () => import("@/views/pages/setting/users/Edit.vue"),
          },
        ],
      },
      {
        path: "/settings/permissions",
        name: "settings.permissions",
        component: () =>
          import("@/views/pages/setting/permissions/Permissions.vue"),
        children: [
          {
            path: "/settings/permissions",
            name: "settings.permissions.index",
            component: () =>
              import("@/views/pages/setting/permissions/Index.vue"),
            props: true,
          },
          {
            path: "/settings/permissions/create",
            name: "settings.permissions.create",
            component: () =>
              import("@/views/pages/setting/permissions/Create.vue"),
          },
          {
            path: "/settings/permissions/:id/edit",
            name: "settings.permissions.edit",
            component: () =>
              import("@/views/pages/setting/permissions/Edit.vue"),
            props: true,
          },
        ],
      },
      {
        path: "/settings/roles",
        name: "settings.roles",
        component: () => import("@/views/pages/setting/roles/roles.vue"),
        children: [
          {
            path: "/settings/roles",
            name: "settings.roles.index",
            component: () => import("@/views/pages/setting/roles/Index.vue"),
            props: true,
          },
          {
            path: "/settings/roles/create",
            name: "settings.roles.create",
            component: () => import("@/views/pages/setting/roles/Create.vue"),
          },
          {
            path: "/settings/roles/:id/edit",
            name: "settings.roles.edit",
            component: () => import("@/views/pages/setting/roles/Edit.vue"),
            props: true,
          },
        ],
      },
      {
        path: "/reports/report_all",
        name: "reports.report_all",
        component: () => import("@/views/pages/base/Baseindex.vue"),
        children: [
          {
            path: "/reports/report_all",
            name: "reports.report_all.index",
            component: () => import("@/views/pages/reports/report_all/Index.vue"),
            props: true,
          }
        ],
      },

      {
        path: "/apps/appeals/appeal",
        name: "apps.appeals.appeals",
        component: () => import("@/views/pages/appeal/appeals/Appeals.vue"),
        children: [
          {
            path: "/apps/appeals/appeal/wait",
            name: "apps.appeals.appeals.wait",
            component: () => import("@/views/pages/appeal/appeals/Wait.vue"),
          },
          {
            path: "/apps/appeals/appeal/receive",
            name: "apps.appeals.appeals.receive",
            component: () => import("@/views/pages/appeal/appeals/Receive.vue"),
          },
          {
            path: "/apps/appeals/appeal/processing",
            name: "apps.appeals.appeals.processing",
            component: () =>
              import("@/views/pages/appeal/appeals/Processing.vue"),
          },
          {
            path: "/apps/appeals/appeal/finish",
            name: "apps.appeals.appeals.finish",
            component: () => import("@/views/pages/appeal/appeals/Finish.vue"),
          },
          {
            path: "/apps/appeals/appeal/:id",
            name: "apps.appeals.appeals.show",
            component: () => import("@/views/pages/appeal/appeals/Show.vue"),
          },
        ],
      },

      {
        path: "/apps/appeals/title",
        name: "apps.appeals.titles",
        component: () => import("@/views/pages/appeal/titles/Titles.vue"),
        children: [
          {
            path: "/apps/appeals/title",
            name: "apps.appeals.titles.index",
            component: () => import("@/views/pages/appeal/titles/Index.vue"),
          },
          {
            path: "/apps/appeals/title/create",
            name: "apps.appeals.titles.create",
            component: () => import("@/views/pages/appeal/titles/Create.vue"),
          },
          {
            path: "/apps/appeals/title/edit",
            name: "apps.appeals.titles.edit",
            component: () => import("@/views/pages/appeal/titles/Edit.vue"),
            props: true,
          },
        ],
      },

      {
        path: "/apps/appeals/sub-title",
        name: "apps.appeals.sub-titles",
        component: () =>
          import("@/views/pages/appeal/sub_titles/Sub_Titles.vue"),
        children: [
          {
            path: "/apps/appeals/sub-title",
            name: "apps.appeals.sub-titles.index",
            component: () =>
              import("@/views/pages/appeal/sub_titles/Index.vue"),
          },
          {
            path: "/apps/appeals/sub-title/create",
            name: "apps.appeals.sub-titles.create",
            component: () =>
              import("@/views/pages/appeal/sub_titles/Create.vue"),
          },
          {
            path: "/apps/appeals/sub-title/edit",
            name: "apps.appeals.sub-titles.edit",
            component: () => import("@/views/pages/appeal/sub_titles/Edit.vue"),
            props: true,
          },
        ],
      },

      {
        path: "/apps/travels/tourist-attraction",
        name: "apps.travels.tourist-attraction",
        component: () =>
          import(
            "@/views/pages/travel/tourist_attraction/Tourist_attraction.vue"
          ),
        children: [
          {
            path: "/apps/travels/tourist-attraction",
            name: "apps.travels.tourist-attraction.index",
            component: () =>
              import("@/views/pages/travel/tourist_attraction/Index.vue"),
          },
          {
            path: "/apps/travels/tourist-attraction/create",
            name: "apps.travels.tourist-attraction.create",
            component: () =>
              import("@/views/pages/travel/tourist_attraction/Create.vue"),
          },
          {
            path: "/apps/travels/tourist-attraction/edit",
            name: "apps.travels.tourist-attraction.edit",
            component: () =>
              import("@/views/pages/travel/tourist_attraction/Edit.vue"),
            props: true,
          },
        ],
      },

      {
        path: "/apps/travels/calendar-travel",
        name: "apps.travels.calendar-travel",
        component: () =>
          import(
            "@/views/pages/travel/calendar_travel/Calendar_travel.vue"
          ),
        children: [
          {
            path: "/apps/travels/calendar-travel",
            name: "apps.travels.calendar-travel.index",
            component: () =>
              import("@/views/pages/travel/calendar_travel/Index.vue"),
          },
          {
            path: "/apps/travels/calendar-travel/create",
            name: "apps.travels.calendar-travel.create",
            component: () =>
              import("@/views/pages/travel/calendar_travel/Create.vue"),
          },
          {
            path: "/apps/travels/calendar-travel/edit",
            name: "apps.travels.calendar-travel.edit",
            component: () =>
              import("@/views/pages/travel/calendar_travel/Edit.vue"),
            props: true,
          },
        ],
      },

      {
        path: "/apps/travels/otop/store",
        name: "apps.travels.otop.store",
        component: () =>
          import(
            "@/views/pages/travel/otop/store/Store.vue"
          ),
        children: [
          {
            path: "/apps/travels/otop/store",
            name: "apps.travels.otop.store.index",
            component: () =>
              import("@/views/pages/travel/otop/store/Index.vue"),
          },
          {
            path: "/apps/travels/otop/store/create",
            name: "apps.travels.otop.store.create",
            component: () =>
              import("@/views/pages/travel/otop/store/Create.vue"),
          },
          {
            path: "/apps/travels/otop/store/edit",
            name: "apps.travels.otop.store.edit",
            component: () =>
              import("@/views/pages/travel/otop/store/Edit.vue"),
              props: true,
          },
          
        ],
      },

      {
        path: "/apps/travels/otop/product-type",
        name: "apps.travels.otop.product-type",
        component: () =>
          import(
            "@/views/pages/travel/otop/product_type/Product_type.vue"
          ),
        children: [
          {
            path: "/apps/travels/otop/product-type",
            name: "apps.travels.otop.product-type.index",
            component: () =>
              import("@/views/pages/travel/otop/product_type/Index.vue"),
          },
          {
            path: "/apps/travels/otop/product-type/create",
            name: "apps.travels.otop.product-type.create",
            component: () =>
              import("@/views/pages/travel/otop/product_type/Create.vue"),
          },
          {
            path: "/apps/travels/otop/product-type/edit",
            name: "apps.travels.otop.product-type.edit",
            component: () =>
              import("@/views/pages/travel/otop/product_type/Edit.vue"),
              props: true,
          },
          
        ],
      },

      {
        path: "/apps/travels/otop/product",
        name: "apps.travels.otop.product",
        component: () =>
          import(
            "@/views/pages/travel/otop/product/Product.vue"
          ),
        children: [
          {
            path: "/apps/travels/otop/product",
            name: "apps.travels.otop.product.index",
            component: () =>
              import("@/views/pages/travel/otop/product/Index.vue"),
          },
          {
            path: "/apps/travels/otop/product/show",
            name: "apps.travels.otop.product.show",
            component: () =>
              import("@/views/pages/travel/otop/product/Show.vue"),
              props: true,
          },
          {
            path: "/apps/travels/otop/product/create",
            name: "apps.travels.otop.product.create",
            component: () =>
              import("@/views/pages/travel/otop/product/Create.vue"),
          },
          {
            path: "/apps/travels/otop/product/edit",
            name: "apps.travels.otop.product.edit",
            component: () =>
              import("@/views/pages/travel/otop/product/Edit.vue"),
              props: true,
          },
          
        ],
      },


      {
        path: "/apps/travels/entrepreneur/store",
        name: "apps.travels.entrepreneur.store",
        component: () =>
          import(
            "@/views/pages/travel/entrepreneur/store/Store.vue"
          ),
        children: [
          {
            path: "/apps/travels/entrepreneur/store",
            name: "apps.travels.entrepreneur.store.index",
            component: () =>
              import("@/views/pages/travel/entrepreneur/store/Index.vue"),
          },
          {
            path: "/apps/travels/entrepreneur/store/show",
            name: "apps.travels.entrepreneur.store.show",
            component: () =>
              import("@/views/pages/travel/entrepreneur/store/Show.vue"),
              props: true,
          },
          {
            path: "/apps/travels/entrepreneur/store/create",
            name: "apps.travels.entrepreneur.store.create",
            component: () =>
              import("@/views/pages/travel/entrepreneur/store/Create.vue"),
          },
          {
            path: "/apps/travels/entrepreneur/store/edit",
            name: "apps.travels.entrepreneur.store.edit",
            component: () =>
              import("@/views/pages/travel/entrepreneur/store/Edit.vue"),
              props: true,
          },
          
        ],
      },

      {
        path: "/apps/travels/entrepreneur/product-type",
        name: "apps.travels.entrepreneur.product-type",
        component: () =>
          import(
            "@/views/pages/travel/entrepreneur/product_type/Product_type.vue"
          ),
        children: [
          {
            path: "/apps/travels/entrepreneur/product-type",
            name: "apps.travels.entrepreneur.product-type.index",
            component: () =>
              import("@/views/pages/travel/entrepreneur/product_type/Index.vue"),
          },
          {
            path: "/apps/travels/entrepreneur/product-type/create",
            name: "apps.travels.entrepreneur.product-type.create",
            component: () =>
              import("@/views/pages/travel/entrepreneur/product_type/Create.vue"),
          },
          {
            path: "/apps/travels/entrepreneur/product-type/edit",
            name: "apps.travels.entrepreneur.product-type.edit",
            component: () =>
              import("@/views/pages/travel/entrepreneur/product_type/Edit.vue"),
              props: true,
          },
          
        ],
      },


      {
        path: "/apps/travels/entrepreneur/product",
        name: "apps.travels.entrepreneur.product",
        component: () =>
          import(
            "@/views/pages/travel/entrepreneur/product/Product.vue"
          ),
        children: [
          {
            path: "/apps/travels/entrepreneur/product",
            name: "apps.travels.entrepreneur.product.index",
            component: () =>
              import("@/views/pages/travel/entrepreneur/product/Index.vue"),
          },
          {
            path: "/apps/travels/entrepreneur/product/show",
            name: "apps.travels.entrepreneur.product.show",
            component: () =>
              import("@/views/pages/travel/entrepreneur/product/Show.vue"),
              props: true,
          },
          {
            path: "/apps/travels/entrepreneur/product/create",
            name: "apps.travels.entrepreneur.product.create",
            component: () =>
              import("@/views/pages/travel/entrepreneur/product/Create.vue"),
          },
          {
            path: "/apps/travels/entrepreneur/product/edit",
            name: "apps.travels.entrepreneur.product.edit",
            component: () =>
              import("@/views/pages/travel/entrepreneur/product/Edit.vue"),
              props: true,
          },
          
        ],
      },


      {
        path: "/apps/travels/market_sun/store",
        name: "apps.travels.market_sun.store",
        component: () =>
          import(
            "@/views/pages/travel/market_sun/store/Store.vue"
          ),
        children: [
          {
            path: "/apps/travels/market_sun/store",
            name: "apps.travels.market_sun.store.index",
            component: () =>
              import("@/views/pages/travel/market_sun/store/Index.vue"),
          },
          {
            path: "/apps/travels/market_sun/store/create",
            name: "apps.travels.market_sun.store.create",
            component: () =>
              import("@/views/pages/travel/market_sun/store/Create.vue"),
          },
          {
            path: "/apps/travels/market_sun/store/edit",
            name: "apps.travels.market_sun.store.edit",
            component: () =>
              import("@/views/pages/travel/market_sun/store/Edit.vue"),
              props: true,
          },
          
        ],
      },

      {
        path: "/apps/travels/market_sun/product-type",
        name: "apps.travels.market_sun.product-type",
        component: () =>
          import(
            "@/views/pages/travel/market_sun/product_type/Product_type.vue"
          ),
        children: [
          {
            path: "/apps/travels/market_sun/product-type",
            name: "apps.travels.market_sun.product-type.index",
            component: () =>
              import("@/views/pages/travel/market_sun/product_type/Index.vue"),
          },
          {
            path: "/apps/travels/market_sun/product-type/create",
            name: "apps.travels.market_sun.product-type.create",
            component: () =>
              import("@/views/pages/travel/market_sun/product_type/Create.vue"),
          },
          {
            path: "/apps/travels/market_sun/product-type/edit",
            name: "apps.travels.market_sun.product-type.edit",
            component: () =>
              import("@/views/pages/travel/market_sun/product_type/Edit.vue"),
              props: true,
          },
          
        ],
      },

      {
        path: "/apps/travels/market_sun/product",
        name: "apps.travels.market_sun.product",
        component: () =>
          import(
            "@/views/pages/travel/market_sun/product/Product.vue"
          ),
        children: [
          {
            path: "/apps/travels/market_sun/product",
            name: "apps.travels.market_sun.product.index",
            component: () =>
              import("@/views/pages/travel/market_sun/product/Index.vue"),
          },
          {
            path: "/apps/travels/market_sun/product/show",
            name: "apps.travels.market_sun.product.show",
            component: () =>
              import("@/views/pages/travel/market_sun/product/Show.vue"),
              props: true,
          },
          {
            path: "/apps/travels/market_sun/product/create",
            name: "apps.travels.market_sun.product.create",
            component: () =>
              import("@/views/pages/travel/market_sun/product/Create.vue"),
          },
          {
            path: "/apps/travels/market_sun/product/edit",
            name: "apps.travels.market_sun.product.edit",
            component: () =>
              import("@/views/pages/travel/market_sun/product/Edit.vue"),
              props: true,
          },
          
        ],
      },



      {
        path: "/apps/travels/market_saiyut/store",
        name: "apps.travels.market_saiyut.store",
        component: () =>
          import(
            "@/views/pages/travel/market_saiyut/store/Store.vue"
          ),
        children: [
          {
            path: "/apps/travels/market_saiyut/store",
            name: "apps.travels.market_saiyut.store.index",
            component: () =>
              import("@/views/pages/travel/market_saiyut/store/Index.vue"),
          },
          {
            path: "/apps/travels/market_saiyut/store/create",
            name: "apps.travels.market_saiyut.store.create",
            component: () =>
              import("@/views/pages/travel/market_saiyut/store/Create.vue"),
          },
          {
            path: "/apps/travels/market_saiyut/store/edit",
            name: "apps.travels.market_saiyut.store.edit",
            component: () =>
              import("@/views/pages/travel/market_saiyut/store/Edit.vue"),
              props: true,
          },
          
        ],
      },

      {
        path: "/apps/travels/market_saiyut/product-type",
        name: "apps.travels.market_saiyut.product-type",
        component: () =>
          import(
            "@/views/pages/travel/market_saiyut/product_type/Product_type.vue"
          ),
        children: [
          {
            path: "/apps/travels/market_saiyut/product-type",
            name: "apps.travels.market_saiyut.product-type.index",
            component: () =>
              import("@/views/pages/travel/market_saiyut/product_type/Index.vue"),
          },
          {
            path: "/apps/travels/market_saiyut/product-type/create",
            name: "apps.travels.market_saiyut.product-type.create",
            component: () =>
              import("@/views/pages/travel/market_saiyut/product_type/Create.vue"),
          },
          {
            path: "/apps/travels/market_saiyut/product-type/edit",
            name: "apps.travels.market_saiyut.product-type.edit",
            component: () =>
              import("@/views/pages/travel/market_saiyut/product_type/Edit.vue"),
              props: true,
          },
          
        ],
      },

      {
        path: "/apps/travels/market_saiyut/product",
        name: "apps.travels.market_saiyut.product",
        component: () =>
          import(
            "@/views/pages/travel/market_saiyut/product/Product.vue"
          ),
        children: [
          {
            path: "/apps/travels/market_saiyut/product",
            name: "apps.travels.market_saiyut.product.index",
            component: () =>
              import("@/views/pages/travel/market_saiyut/product/Index.vue"),
          },
          {
            path: "/apps/travels/market_saiyut/product/show",
            name: "apps.travels.market_saiyut.product.show",
            component: () =>
              import("@/views/pages/travel/market_saiyut/product/Show.vue"),
              props: true,
          },
          {
            path: "/apps/travels/market_saiyut/product/create",
            name: "apps.travels.market_saiyut.product.create",
            component: () =>
              import("@/views/pages/travel/market_saiyut/product/Create.vue"),
          },
          {
            path: "/apps/travels/market_saiyut/product/edit",
            name: "apps.travels.market_saiyut.product.edit",
            component: () =>
              import("@/views/pages/travel/market_saiyut/product/Edit.vue"),
              props: true,
          },
          
        ],
      },







      {
        path: "/apps/smarthealths/smarthealths_admin",
        name: "apps.smarthealths.smarthealths-admin",
        component: () =>
          import(
            "@/views/pages/smarthealth/smarthealths_admin/Smarthealths_admin.vue"
          ),
        children: [
          {
            path: "/apps/smarthealths/smarthealths_admin",
            name: "apps.smarthealths.smarthealths-admin.index",
            component: () =>
              import("@/views/pages/smarthealth/smarthealths_admin/Index.vue"),
          },
          {
            path: "/apps/smarthealths/smarthealths_admin/create",
            name: "apps.smarthealths.smarthealths-admin.create",
            component: () =>
              import("@/views/pages/smarthealth/smarthealths_admin/Create.vue"),
          },
        ],
      },



      {
        path: "/apps/taxs/tax",
        name: "apps.taxs.taxs",
        component: () => import("@/views/pages/tax/taxs/Taxs.vue"),
        children: [
          {
            path: "/apps/taxs/tax/wait",
            name: "apps.taxs.taxs.wait",
            component: () => import("@/views/pages/tax/taxs/Wait.vue"),
          },
          {
            path: "/apps/taxs/tax/processing",
            name: "apps.taxs.taxs.processing",
            component: () =>
              import("@/views/pages/tax/taxs/Processing.vue"),
          },
          {
            path: "/apps/taxs/tax/finish",
            name: "apps.taxs.taxs.finish",
            component: () => import("@/views/pages/tax/taxs/Finish.vue"),
          },
          {
            path: "/apps/taxs/tax/:id",
            name: "apps.taxs.taxs.show",
            component: () => import("@/views/pages/tax/taxs/Show.vue"),
          },
          {
            path: "/apps/taxs/notify",
            name: "apps.taxs.notify",
            component: () => import("@/views/pages/tax/notify/Notify.vue"),
            children: [
              {
                path: "/apps/taxs/notify",
                name: "apps.taxs.notify.index",
                component: () => import("@/views/pages/tax/notify/Index.vue"),
              },
              {
                path: "/apps/taxs/notify/edit",
                name: "apps.taxs.notify.edit",
                component: () => import("@/views/pages/tax/notify/Edit.vue"),
                props: true,
              },
            ]
          },
          {
            path: "/apps/taxs/tax/report",
            name: "apps.taxs.taxs.report",
            component: () => import("@/views/pages/tax/taxs/Report.vue"),
          },
        ],
      },
      {
        path: "/apps/building_permit",
        name: "apps.building_permit",
        component: () => import("@/views/pages/building_permit/Building_permit.vue"),
        children: [
          {
            path: "/apps/building_permit/wait",
            name: "apps.building_permit.wait",
            component: () => import("@/views/pages/building_permit/Wait.vue"),
          },
          {
            path: "/apps/building_permit/:id",
            name: "apps.building_permit.show",
            component: () => import("@/views/pages/building_permit/Show.vue"),
          },
        ],
      },
      {
        path: "/apps/building_permits",
        name: "apps.building_permits",
        component: () => import("@/views/pages/building_permit/Building_permit.vue"),
        children: [
          {
            path: "/apps/building_permits/wait",
            name: "apps.building_permits.wait",
            component: () => import("@/views/pages/building_permits/Wait.vue"),
          },
          {
            path: "/apps/building_permits/:id",
            name: "apps.building_permits.show",
            component: () => import("@/views/pages/building_permits/Show.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/views/pages/auth/login_pages/Login"),
    children: [
      {
        name: "login",
        path: "/login",
        component: () => import("@/views/pages/auth/login_pages/Login"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state

  store.dispatch(VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
