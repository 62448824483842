import { defineAbility } from "@casl/ability";
import JwtService from "@/core/services/JwtService";
let CryptoJS = require("crypto-js");

export default defineAbility((can) => {
  if (JwtService.getPermissions()) {
    let roles = JSON.parse(JwtService.getPermissions());
    let bytes = CryptoJS.AES.decrypt(roles, process.env.VUE_APP_KEY);
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    for (let i = 0; i < decryptedData.length; i++) {
      can(decryptedData[i], "all");
    }
    can("dashboard", "all");
  }
});
