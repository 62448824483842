<template>
  <router-view />
</template>

<style lang="scss">
@import "~sweetalert2/dist/sweetalert2.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";
// Main demo style scss
@import "assets/sass/plugins";
@import "assets/sass/style";

@import url("https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mitr:wght@200;300;400&display=swap");

:root {
  --color: #829ed2;
}
html,
body {
  font-family: "Sarabun", sans-serif !important;
}

#app {
  font-family: "Sarabun", sans-serif !important;
}
a {
  text-decoration: none !important;
}
.logoname {
  font-size: 16px;
  font-weight: 600;
  color: black;
}
.swal2-icon.swal2-error.swal2-icon-show {
  margin: auto;
}
.swal2-icon.swal2-success.swal2-icon-show {
  margin: auto;
}
.swal2-icon.swal2-question.swal2-icon-show {
  margin: auto;
}
.table td {
  border-width: 0em 0;
}
</style>
