export const SET_STATUS = "setStatus";

export default {
  state: {
    status: [],
  },
  getters: {
    getStatusLogin(state) {
      return state.status;
    },
  },
  actions: {
    [SET_STATUS](state, payload) {
      state.commit(SET_STATUS, payload);
    },
  },
  mutations: {
    [SET_STATUS](state, payload) {
      state.status = payload;
    },
  },
};
