import { createStore } from "vuex";

import AuthModule from "@/store/modules/AuthModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import LoginModule from "@/store/modules/LoginModule";
const store = createStore({
  modules: {
    AuthModule,
    BreadcrumbsModule,
    LoginModule,
  },
});

export default store;
